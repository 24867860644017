import React from 'react';

import Image from 'next/image';
import Link from 'next/link';

import {useSelector} from 'react-redux';

import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper';
import {MdKeyboardArrowRight, MdKeyboardArrowLeft} from 'react-icons/md';
import 'swiper/css';

import {getSymbol} from '../../helpers/contract';

const ArtsCarousel = ({items}) =>
{
  const rate = useSelector((state) => state.rate.value);

  return (
    <>
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        slidesPerView="auto"
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          1100: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }}
        className=" card-slider-4-columns !py-5"
      >
        {items.map(({id, image, video, title, price, chain_id}) =>
        {
          return (
            <SwiperSlide key={id}>
              <article>
                <div className="dark:bg-jacarta-700 rounded-lg block overflow-hidden bg-white shadow-md transition-shadow hover:shadow-lg">
                  <figure className="relative">
                    <Link href={`/project/${id}`}>
                      {video ?
                        <video playsInline muted loop autoPlay>
                          <source src={video}/>
                        </video> :
                        <Image
                          src={image}
                          alt={title}
                          height="430"
                          width="379"
                          layout="responsive"
                          className="swiper-lazy h-[430px] w-full object-cover swiper-lazy-loaded rounded-2.5xl"
                        />
                      }
                    </Link>
                  </figure>
                  <div className="p-6">
                    <div className="flex">
                      <Link href={`/project/${id}`} className="block w-full">
                        <span className="font-display hover:text-accent text-jacarta-700 text-lg leading-none dark:text-white">{title}</span>

                        {rate[chain_id] ?
                          <div className="mt-2 flex items-center justify-between">
                            <div className="flex items-center whitespace-nowrap">
                              <span className="-ml-1">
                                <svg className="icon mr-1 h-4 w-4">
                                  <use xlinkHref={`/icons.svg#icon-${getSymbol(chain_id)}`}></use>
                                </svg>
                              </span>

                              <span className="text-green text-lg font-medium leading-tight tracking-tight">{(rate[chain_id] * price).toPrecision(3)} {getSymbol(chain_id)}</span>
                            </div>

                            <span className="dark:text-jacarta-300 text-jacarta-400 text-sm">~${price}</span>
                          </div>
                          : ''}
                      </Link>
                    </div>
                  </div>
                </div>
              </article>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="group swiper-button-prev shadow-white-volume absolute !top-1/2 !-left-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-left-6 after:hidden">
        <MdKeyboardArrowLeft/>
      </div>

      <div className="group swiper-button-next shadow-white-volume absolute !top-1/2 !-right-4 z-10 -mt-6 flex !h-12 !w-12 cursor-pointer items-center justify-center rounded-full bg-white p-3 text-jacarta-700 text-xl sm:!-right-6 after:hidden">
        <MdKeyboardArrowRight/>
      </div>
    </>
  );
};

export default ArtsCarousel;