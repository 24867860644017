import React from 'react';
import Hero from '../../components/hero/hero';
import Meta from '../../components/Meta';

const Home_2 = ({items}) =>
{
  return (
    <>
      <Meta title="M3 Games"/>
      <Hero items={items}/>
    </>
  );
};

export default Home_2;