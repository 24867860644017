import React, {useContext, useEffect} from 'react';

import UserContext from '../components/UserContext';
import Home_2 from './home/home_2';

import * as Request from '../helpers/request';

export default function Home({items})
{
  const {scrollRef} = useContext(UserContext);

  useEffect(() =>
  {
    window.scrollTo(0, scrollRef.current.scrollPos);

    const handleScrollPos = () => scrollRef.current.scrollPos = window.scrollY;

    window.addEventListener('scroll', handleScrollPos);
    return () => window.removeEventListener('scroll', handleScrollPos);
  });

  return (
    <div>
      <Home_2 items={items}/>
    </div>
  );
}

export async function getServerSideProps()
{
  const result = await Request.GET(`/project`, {search_by: 'lock,mint_amount', search_value: '0&&>0'}, {}, process.env.DOMAIN_BACK);

  if (result.status === 200)
  {
    return {
      props: {
        items: result.body.data.map((item) =>
          new Object({
            id: item.id,
            href: `/project/${item.id}`,
            price: item.mint_price,
            chain_id: `0x${Number(item.network.id).toString(16)}`,
            image: item.assets ? process.env.NEXT_PUBLIC_DOMAIN_BACK + item.assets.image : '',
            video: item.assets ? process.env.NEXT_PUBLIC_DOMAIN_BACK + item.assets.video : '',
            title: item.title
          }))
      }
    };
  }

  return {};
}