import React from 'react';

import Image from 'next/image';

import ArtsCarousel from '../carousel/artCarousel';

const Hero = ({items}) =>
{
  return (
    <>
      <section className="hero relative py-20 md:pt-32">
        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
          <figure className="h-[630px] w-full">
            <Image src="/images/gradient.jpg" alt="gradient" layout="fill" width="1920" height="900"/>
          </figure>
        </picture>

        <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
          <figure className="h-[630px] w-full">
            <Image
              src="/images/gradient_dark.jpg"
              alt="gradient dark"
              layout="fill"
              width="1920"
              height="900"
            />
          </figure>
        </picture>

        <div className="container">
          <div className="py-24 text-center">
            <h1 className="text-jacarta-700 font-display mb-6 text-5xl dark:text-white lg:text-6xl xl:text-7xl">
              Collect Gaming Assets
            </h1>
            <p className="dark:text-jacarta-200 mb-8 text-lg">
              Buy and sell NFTs from our first collection
            </p>
          </div>

          <div className="relative">
            {items ? <ArtsCarousel items={items}/> : ''}
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;